import 'what-input' // what-input helps us determining the user's input method (currently used to only show custom outline styles for keyboard users)
import WidgetLoader from './widgetLoader'
import {iframeResizer} from './widgets/iframe-resizer'

function init() {
  const wl = new WidgetLoader()
  // feedback overlay on video pages. this can be removed once the video article comes from the new React app.
  wl.searchAndInitWidgets()
  iframeResizer()
}

if (document.readyState !== 'loading') {
  init()
} else {
  document.onreadystatechange = function() {
    if (document.readyState === 'interactive') {
      init()
    }
  }
}
