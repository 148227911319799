export default class Modal {
    constructor({withSemiTransparentBackground = true, contentHTML = ''}) {
        this.render(withSemiTransparentBackground, contentHTML);
        this.initBindings();
    }

    render(withSemiTransparentBackground, contentHTML) {
        const html = `
            <div class="sz-modal">
                <div class="sz-modal__header">
                    <a class="js-close-modal sz-modal__header__close" href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24">
                            <g fill="none" fill-rule="evenodd" stroke="#29293A" stroke-linecap="square" stroke-width="1.5">
                                <path d="M1.96 1.06l21.213 21.214M23.88 1.06L2.667 22.275"/>
                            </g>
                        </svg>
                    </a>
                </div>
                <div class="sz-modal__content">
                    ${contentHTML}
                </div>
                <div class="sz-modal__footer"></div>
            </div>
        `;

        const overlayDiv = document.createElement('div');
        overlayDiv.classList.add('sz-overlay');
        overlayDiv.classList.add(withSemiTransparentBackground ? 'sz-overlay--white-transparent' : 'sz-overlay--transparent');
        overlayDiv.classList.add('js-overlay');
        overlayDiv.innerHTML = html;

        document.body.appendChild(overlayDiv);
    }

    initBindings() {
        const closeLink = document.querySelector('.js-close-modal');
        closeLink.addEventListener('click', e => {
            e.preventDefault();
            this.closeModal();
        });

        const overlay = document.querySelector('.js-overlay');
        overlay.addEventListener('click', e => {
            if (e.target.classList.contains('js-overlay')) {
                this.closeModal();
            }
        });
    }

    closeModal() {
        document.body.removeChild(document.querySelector('.js-overlay'));
    }
}