import {
    cond,
    identity,
    is,
    isNil,
    pipe,
    prop,
    T,
    tap,
    tryCatch,
} from 'ramda';

const IFRAME_SELECTOR = '.sz-module.include iframe[data-bind], iframe[data-resizable="true"]';

// :: * -> Object
const parseJsonObj = tryCatch(JSON.parse, () => ({}));

// :: Event -> Object
const parseEventDataJson = pipe(
    prop('data'),
    parseJsonObj,
);

// Extracts the `sizing` attribute from an event's data json
// :: Event -> String | undefined
export const extractSizing = pipe(
    parseEventDataJson,
    prop('sizing'),
    cond([
        // message event is also sent to do other things than resizing
        // we don't want to log these events
        [isNil, identity],
        [is(Number), num => `${num}px`],
        [T, tap(num => console.warn("iFrame resizing failed because sizing attribute is not a number"))]
    ])
);

// Determines if the given event was emitted by the given iframe.
// Does so by checking if the window objects of the potential iframe and the event source's iframe are the same.
// :: Event -> HTMLIframeElement -> Boolean
export const eventWasDispatchedByIframe = event => iframe => iframe.contentWindow === event.source;

// Handles the resizing of iframes (mostly for include modules).
// Iframes have to follow the Iframe Resizing Contract: https://confluence.swmh.de/display/DOCSDEMAIN/Iframe+Resizing
// Expects a `sizing` property in the JSON string inside the `message` event on window.
export const iframeResizer = () => {
    const iframes = Array.from(document.querySelectorAll(IFRAME_SELECTOR));

    const iframeResizeHandler = event => {
        // finds which iframe emitted a `message` event
        const iframe = iframes.find(eventWasDispatchedByIframe(event));
        if (iframe) {
            const height = extractSizing(event);
            if (height) iframe.style.setProperty('height', height);
        }
    }

    // handle past events that were cached
    (window.__POSTMESSAGE_CACHE__ || []).forEach(iframeResizeHandler);

    // set up for future events
    window.addEventListener('message', iframeResizeHandler);
};
