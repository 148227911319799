export default {
    getBaseUrl() {
        const environment = this.getEnvironment();
        let url = 'https://www.sueddeutsche.de';

        switch (environment) {
            case 'dev':
                url = '//localhost:8080';
                break;
            case 'stage':
                url = 'https://www-stage.sueddeutsche.de';
                break;
            case 'ci':
                url = '//www-ci.sueddeutsche.de';
                break;
        }

        return url;
    },

    getEnvironment() {
        return document.body.getAttribute('data-env');
    }
};
