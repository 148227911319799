import feedback from './widgets/feedback';

export default class WidgetLoader {
  searchAndInitWidgets() {
    const widgetsElements = Array.from(document.querySelectorAll('[data-szwidget], [data-bind]'));

    widgetsElements.forEach(widgetElement => {
      const widget = this.getWidgetDataIfExists(widgetElement);

      if (!widget) {
        return;
      }

      switch (widget.name) {
        case 'feedback': {
          feedback.init(widgetElement);
          break;
        }
      }
    });
  }

  /**
   * Gets widget data if available for the incoming node.
   *
   * @param {node} widgetElement
   * @returns {boolean|object}
   */
  getWidgetDataIfExists(widgetElement) {
    return this.isWidget(widgetElement) && this.getWidgetNameAndParams(widgetElement);
  }

  /**
   * Checks if the incoming node/element is an widget we need to deal with.
   *
   * @param {node} widgetElement
   * @returns {boolean}
   */
  isWidget(widgetElement) {
    // the new way
    if (widgetElement.dataset.szwidget) {
      return true;
    }

    // the old/deprecated way
    // @todo remove when content stuff is moved from polopoly
    if (widgetElement.dataset.bind && (widgetElement.dataset.bind.indexOf('widget.') !== -1) || widgetElement.dataset.bind.indexOf('zoomimage') !== -1) {
      return true;
    }

    return false;
  }

  /**
   * Returns an object that looks like { name: 'xyz', params: {x: 'x', y: 'y' } }
   *
   * @param {node} widgetElement
   * @returns {object}
   */
  getWidgetNameAndParams(widgetElement) {
    // the new way
    if (widgetElement.dataset.szwidget) {
      return {
        name: widgetElement.dataset.szwidget,
        params: widgetElement.dataset.szwidgetParams
      };
    }

    // the old/deprecated way ...
    // @todo remove when content stuff is moved from polopoly
    const bindedData = JSON.parse(widgetElement.dataset.bind);
    const keys = Object.keys(bindedData);

    if (keys.length === 0) {
      return;
    }

    const name = keys[0]; // i.e. "widget.Newsscanner"

    if (name.indexOf('widget.') === -1 && name.indexOf('zoomimage') === -1) {
      return;
    }

    const params = bindedData[name];

    return {
      name,
      params
    };
  }
}
