import axios from 'axios'
import helper from '../helper'
import Modal from '../utils/modal'

class Feedback {
    constructor(widgetElement) {
        this.widgetElement = widgetElement

        widgetElement.addEventListener('click', event => {
            event.preventDefault();
            this.loadContent(this.show)
        })
    }

    loadContent(successFunction) {
        const {feedbackemail, articleheader, articleurl} = this.widgetElement.dataset;

        axios.get(`${helper.getBaseUrl()}/szmodules/feedback?email=${feedbackemail}&articleHeader=${encodeURI(articleheader)}&articleUrl=${articleurl}`)
            .then(response => successFunction(response.data))
            .catch(error => console.log(error))
    }


    show(content) {
        new Modal({ contentHTML: content });
    }
}

export default {
    init: widgetElement => {
        new Feedback(widgetElement)
    }
}
